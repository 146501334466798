import { Box, Typography, styled } from "@mui/material";
import React from "react";
import backgroundImage from "../assets/cloth.jpg";
import insta from "../assets/insta.png";
import fb from "../assets/fb.png";

const OuterBox = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // color: "#f7bd02",
  fontSize: "2rem",
});

const InnerBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.30)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const HeadTypo = styled(Typography)({
  // width: "50%",
  border: "5px solid #fff",
  margin: "3%",
  padding: "3% 4%",
  fontWeight: "bold",
  textAlign: "center",
  color: "#ffffff",
  letterSpacing: "0.7rem",
  "@media (max-width: 768px)": {
    fontSize: "2.5rem",
  },
  "@media (max-width: 425)": {
    fontSize: "0.5rem",
    marginBottom: "20px",
  },
});

const Bablisa = styled(Typography)({
  fontWeight: "bold",
  textAlign: "center",
  color: "#f7bd02",
  letterSpacing: "0.7rem",
  "@media (max-width: 768px)": {
    fontSize: "2.5rem",
  },
});

const SubTypo = styled(Typography)({
  letterSpacing: "0.8rem",
  "@media (max-width: 768px)": {
    letterSpacing: "0.4rem",
  },
});

const SocialBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
  position: "relative",
});

const Image = styled("img")({
  width: "10%",
  cursor: "pointer",
  margin: "0 3%",
});

function Insta() {
  const url = "https://www.instagram.com/bablisa_official/";
  window.location.replace(url);
}

const ComingSoon = () => {
  return (
    <OuterBox>
      <InnerBox>
        {/* <img src={logo} alt="insta" style={{ width: "20%" }} /> */}
        <Bablisa variant="h2">BABLISA</Bablisa>
        <HeadTypo variant="h1">
          COMING SOON
          <SubTypo>Everyday Fashion for you</SubTypo>
        </HeadTypo>
        <SocialBox>
          <Typography
            style={{
              color: "#fff",
              position: "absolute",
              top: -35,
            }}
          >
            Connect us on:
          </Typography>
          <Image src={insta} alt="insta" onClick={Insta} />
          <Image src={fb} alt="insta" onClick={Insta} />
        </SocialBox>
      </InnerBox>
    </OuterBox>
  );
};

export default ComingSoon;
